import PhoneBox from "../../contact/phoneBox.component"
import Link from "../link.component"
import { Spacer } from "../../structure/theme/styles.component"
import FooterDidomiItem from "./footerDidomiItem"
import { Copyright } from "./simplifiedFooter.component"
import EscdaLazy from "../../landing/escda/escdaLazy.component"
import React from "react"
import { useCoBrand } from "../../coBrand/coBrand.provider"
import { useUserContext } from "../user.provider"
import { fontSizes } from "../../structure/theme"
import { useTranslation } from "../../translation/translate.component"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const RouteList = styled.ul`
  list-style-type: none;
  padding-left: 0;

  span,
  a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    width: 100%;
    padding: 5px 0;
    display: inline-block;
  }
`

const Title = styled.div`
  margin-bottom: 3px;
  margin-top: 10px;
  font-weight: 900;
  font-size: ${fontSizes(`large`)};
`

const Star = styled.p`
  line-height: 16px;
  font-size: ${fontSizes(`small`)};
  font-weight: 300;
  margin-left: 10px;
`

const Schedule = styled.p`
  font-size: ${fontSizes(`small`)};
  margin-top: 0;
`

const Column = styled.div`
  width: 100%;
  color: inherit;

  ${breakpoint(`medium`)`
    width: 25%;
  `}

  &:last-child {
    margin: 15px 0;

    ${breakpoint(`small`)`
      margin-bottom: 20px;
    `}
  }
`

const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const PhoneBoxWrapper = styled.div`
  width: 280px;
  max-width: 100%;
  padding: 15px 0;
`

export default function FullFooter () {
  const { coBrand, coBrandReady } = useCoBrand()
  const isCobrand = coBrandReady && !!coBrand
  const { usePhoneNumber } = useUserContext()
  const phoneNumber = usePhoneNumber()
  const { t } = useTranslation()

  return (
    <Wrapper>
      {isCobrand && phoneNumber && (
        <Column>
          <Title data-testid="footer_label">{t(`common:navigation.footer_title`)}</Title>
          <PhoneBoxWrapper data-testid="cobrand_footer_phone">
            <PhoneBox phoneNumber={phoneNumber} />
          </PhoneBoxWrapper>
          <Schedule>{t(`common:navigation.footer_schedule`)}</Schedule>
        </Column>
      )}
      {!isCobrand && (
        <Column>
          <Title data-testid="footer_label">{t(`common:navigation.footer_questions`)}</Title>
          <RouteList>
            <li>
              <Link route="faq" data-testid="footer_faq">
                {t(`common:navigation.footer_faq`)}
              </Link>
            </li>
            <li>
              <Link route="commentCaMarche" data-testid="footer_comment_ca_marche">
                {t(`common:navigation.footer_how`)}
              </Link>
            </li>
            <li>
              <Link route="contact" data-testid="footer_contact">
                {t(`common:navigation.footer_contact`)}
              </Link>
            </li>
          </RouteList>
          {phoneNumber && (
            <>
              <PhoneBoxWrapper data-testid="footer_phone">
                <PhoneBox phoneNumber={phoneNumber} />
              </PhoneBoxWrapper>
              <Schedule>{t(`common:navigation.footer_schedule`)}</Schedule>
            </>
          )}
        </Column>
      )}
      <Spacer />
      <Column>
        <Title data-testid="footer_label">À PROPOS</Title>
        <RouteList>
          {!isCobrand && (
            <li>
              <Link route="aPropos" data-testid="footer_a_propos">
                {t(`common:navigation.footer_about`)}
              </Link>
            </li>
          )}

          <li>
            <Link route="mentionsLegales" data-testid="footer_mentions_legales">
              {t(`common:navigation.footer_legal`)}
            </Link>
          </li>
          <li>
            <Link route="conditionsJuridiques" data-testid="footer_conditions_juridiques">
              {t(`common:navigation.footer_juridic`)}
            </Link>
          </li>
          <li>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://www.homeserve.fr/politique-protection-donnees-personnelles?lien_interne=Footer"
              data-testid="footer_donnees_personnelles"
            >
              {t(`common:navigation.footer_private_data`)}
            </Link>
          </li>
          <li>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://www.homeserve.fr/politique-des-cookies?lien_interne=Footer"
              data-testid="footer_politique_des_cookies"
            >
              {t(`common:navigation.footer_cookies`)}
            </Link>
          </li>
          <li>
            <FooterDidomiItem />
          </li>
          {APP_CONFIG.featureFlags.page_sitemap && !isCobrand && (
            <li>
              <Link route="sitemap" data-testid="footer_sitemap">
                {t(`common:navigation.footer_sitemap`)}
              </Link>
            </li>
          )}
        </RouteList>
      </Column>
      <Spacer />
      <Column>
        <Copyright data-testid="footer_copyright">Ⓒ {APP_CONFIG.copyright}</Copyright>
        <EscdaLazy data-testid="footer_escda">
          <Star>
            {t(`common:escda.footer`)}
            {` `}
            <ExternalLink target="_blank" rel="noreferrer" href="https://www.escda.fr/">
              escda.fr
            </ExternalLink>
            .
          </Star>
        </EscdaLazy>
      </Column>
    </Wrapper>
  )
}
